var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showLeadsDuplicates)?_c('div',[_c('lead-duplicates')],1):_c('div',{attrs:{"id":"lead-kanban"}},[_c('v-card',{staticClass:"mx-auto flexcard",staticStyle:{"margin":"2%","padding-top":"0px"},attrs:{"color":"","elevation":"0","height":"100%","width":"95%"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"11 "}},[_c('LeadSearchField',{staticStyle:{"padding-top":"10px"},attrs:{"showContactKpis":_vm.showContactKpis,"sources":_vm.sources},on:{"getShowContactKpis":value => (_vm.showContactKpis = value)}})],1)],1),_c('v-expand-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showContactKpis),expression:"!showContactKpis"}],staticClass:"rounded-0",staticStyle:{"margin-top":"10px"},attrs:{"color":"","elevation":"0"}},[_c('div',[_c('lead-how-did-contact-us-kpis',{staticStyle:{"padding-bottom":"0px"},attrs:{"leads":_vm.leads},on:{"getFilter":_vm.filterHowDidContactUs,"getSource":value => (_vm.sources = value)}})],1)])],1)],1),(_vm.filterLeadsSearchedSt)?_c('div',{staticClass:"lists"},_vm._l((_vm.getItemsSearchedKanban(_vm.leadsSearchedSt)),function(item,i){return _c('div',{key:i},[_c('div',{class:`list list-${item.value}`},[_c('header',[(item.value === 'discarded')?_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-delete ")]):_vm._e(),(item.value === 'finished')?_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-trophy ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.text)+" ")]),(
                _vm.getValuePhase(_vm.filterLeadStatus, item.value) !== item.value
              )?_c('span',{staticClass:"badge badge-info pos-badge"},[_vm._v(" ( 0) ")]):(item && item.footer && item.footer.total)?_c('span',{staticClass:"badge badge-info pos-badge"},[_vm._v(" ("+_vm._s(item.footer.total)+") ")]):_c('span',{staticClass:"badge badge-info pos-badge"},[_vm._v(" (0) ")])],1),(item.leads.length <= 0)?_c('ul',[_vm._v(" Parece que no hay Leads en esta fase. ")]):_c('ul',_vm._l((item.leads),function(lead,index){return _c('div',{key:index,staticClass:"border-default card auto lead-card-container",class:_vm.userRole === 'marketing' ? 'no-hover-pointer' : '',on:{"click":function($event){return _vm.setActualLead(lead._id)}}},[_c('lead-card',{attrs:{"lead":lead,"contactLeadName":lead.contact_lead_name,"srcBrokerImage":lead.internal_broker
                    ? _vm.brokerImageFormat(lead.internal_broker)
                    : _vm.brokerImageFormat(lead.internal_admin),"brokerName":_vm.getBrokerName(lead),"observations":lead.observations,"observations_detail":lead.observations_detail,"budget":parseInt(lead.budget),"currency":lead.currency,"profilePercentage":_vm._f("percentageFormat")(lead.profile_percentage)}})],1)}),0),_c('footer')])])}),0):_c('div',[(_vm.leadsKanbanSt && _vm.leadsKanbanSt.length > 0)?_c('div',{class:_vm.showDiscardedLeads ? '' : 'lists'},_vm._l((_vm.getItemsKanban(_vm.leadsKanbanSt)),function(item,i){return _c('div',{key:i},[(_vm.showDiscardedLeads)?_c('div',{class:`list-discarded-only`},[_c('header',[(item.value === 'discarded')?_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-delete ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.text)+" ")]),(
                  _vm.getValuePhase(_vm.filterLeadStatus, item.value) !== item.value
                )?_c('span',{staticClass:"badge badge-info pos-badge"},[_vm._v(" (0) ")]):(item && item.footer && item.footer.total)?_c('span',{staticClass:"badge badge-info pos-badge"},[_vm._v(" ("+_vm._s(item.footer.total)+") ")]):_c('span',{staticClass:"badge badge-info pos-badge"},[_vm._v(" (0) ")])],1),(item.loading)?_c('v-row',_vm._l((10),function(item,index){return _c('div',{key:index,staticClass:"mx-2"},[_c('v-skeleton-loader',{staticClass:"my-4",attrs:{"width":"350px","type":"list-item-avatar-two-line,list-item, actions"}})],1)}),0):(_vm.someFilterSelected && item.leads.length <= 0)?_c('v-row',{staticClass:"my-10 mx-2 grey--text"},[_c('v-card',{attrs:{"elevation":"0","color":"transparent"}},[_vm._v(" No se encontraron Leads con los filtros seleccionados. ")])],1):(!_vm.someFilterSelected && item.leads.length <= 0)?_c('v-row',{staticClass:"my-10 mx-2 grey--text"},[_c('v-card',{attrs:{"elevation":"0","color":"transparent"}},[_vm._v(" Parece que no hay Leads en esta fase. ")])],1):(
                _vm.getValuePhase(_vm.filterLeadStatus, item.value) !== item.value
              )?_c('v-row',[_c('v-card',{staticClass:"my-10 mx-2 grey--text",attrs:{"elevation":"0","color":"transparent"}},[_vm._v(" Tiene seleccionada una fase distinta a esta en los filtros. ")])],1):_c('v-row',[_vm._l((item.leads),function(lead,index){return _c('div',{key:index,staticClass:"mx-2 border-default card auto lead-card-container",class:_vm.userRole === 'marketing' ? 'no-hover-pointer' : '',on:{"click":function($event){return _vm.setActualLead(lead._id)}}},[_c('lead-card',{attrs:{"lead":lead,"contactLeadName":lead.contact_lead_name,"srcBrokerImage":lead.internal_broker
                      ? _vm.brokerImageFormat(lead.internal_broker)
                      : _vm.brokerImageFormat(lead.internal_admin),"brokerName":_vm.getBrokerName(lead),"observations":lead.observations,"observations_detail":lead.observations_detail,"budget":parseInt(lead.budget),"currency":lead.currency,"profilePercentage":_vm._f("percentageFormat")(lead.profile_percentage)}})],1)}),(_vm.loadMoreAvailable(item.footer))?_c('div',[_c('v-btn',{staticClass:"my-4 py-4",attrs:{"color":"primary","disabled":item.more,"loading":item.more,"block":""},on:{"click":function($event){return _vm.loadMoreLeadsByPhase(item)}}},[_vm._v(" Cargar más "),_c('v-icon',{staticClass:"mx-2"},[_vm._v(" mdi-plus-circle ")])],1)],1):_vm._e()],2),_c('footer')],1):_c('div',{class:`list list-${item.value}`},[_c('header',[(item.value === 'discarded')?_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-delete ")]):_vm._e(),(item.value === 'finished')?_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-trophy ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.text)+" ")]),(
                  _vm.getValuePhase(_vm.filterLeadStatus, item.value) !== item.value
                )?_c('span',{staticClass:"badge badge-info pos-badge"},[_vm._v(" ( 0) ")]):(item && item.footer && item.footer.total)?_c('span',{staticClass:"badge badge-info pos-badge"},[_vm._v(" ("+_vm._s(item.footer.total)+") ")]):_c('span',{staticClass:"badge badge-info pos-badge"},[_vm._v(" (0) ")])],1),(item.loading)?_c('ul',_vm._l((10),function(item,index){return _c('div',{key:index},[_c('v-skeleton-loader',{staticClass:"my-4",attrs:{"type":"list-item-avatar-two-line,list-item, actions"}})],1)}),0):(_vm.someFilterSelected && item.leads.length <= 0)?_c('ul',{staticClass:"grey--text"},[_vm._v(" No se encontraron Leads con los filtros seleccionados. ")]):(!_vm.someFilterSelected && item.leads.length <= 0)?_c('ul',[_vm._v(" Parece que no hay Leads en esta fase. ")]):(
                _vm.getValuePhase(_vm.filterLeadStatus, item.value) !== item.value
              )?_c('ul',[_vm._v(" Tiene seleccionada una fase distinta a esta en los filtros. ")]):_c('ul',[_vm._l((item.leads),function(lead,index){return _c('div',{key:index,staticClass:"border-default card auto lead-card-container",class:_vm.userRole === 'marketing' ? 'no-hover-pointer' : '',on:{"click":function($event){return _vm.setActualLead(lead._id)}}},[_c('lead-card',{attrs:{"lead":lead,"contactLeadName":lead.contact_lead_name,"srcBrokerImage":lead.internal_broker
                      ? _vm.brokerImageFormat(lead.internal_broker)
                      : _vm.brokerImageFormat(lead.internal_admin),"brokerName":_vm.getBrokerName(lead),"observations":lead.observations,"observations_detail":lead.observations_detail,"budget":parseInt(lead.budget),"currency":lead.currency,"profilePercentage":_vm._f("percentageFormat")(lead.profile_percentage)}})],1)}),(_vm.loadMoreAvailable(item.footer))?_c('div',[_c('v-btn',{staticClass:"my-4 py-4",attrs:{"color":"primary","disabled":item.more,"loading":item.more,"block":""},on:{"click":function($event){return _vm.loadMoreLeadsByPhase(item)}}},[_vm._v(" Cargar más "),_c('v-icon',{staticClass:"mx-2"},[_vm._v(" mdi-plus-circle ")])],1)],1):_vm._e()],2),_c('footer')])])}),0):_c('div',[_c('v-card',{attrs:{"width":"100vw","height":"90vh","elevation":"0"}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"width":5,"size":100,"color":"primary","indeterminate":""}})],1),_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('div',[_vm._v(" Cargando Leads ... ")])])],1)],1)],1)],1)])],1),_c('lead-detail-modal',{ref:"leadDetailModal",attrs:{"page":'kanban',"dialog":_vm.isDialogActive},on:{"setDialog":_vm.setDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }