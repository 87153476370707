<template>
  <div>
    <div v-if="showLeadsDuplicates">
      <lead-duplicates />
    </div>
    <div v-else id="lead-kanban">
      <v-card
        style="margin: 2%; padding-top: 0px;"
        color=""
        class="mx-auto flexcard"
        elevation="0"
        height="100%"
        width="95%"
      >
        <v-row align="center" justify="center">
          <v-col cols="11 " class="justify-center">
            <LeadSearchField
              style="padding-top: 10px;"
              :showContactKpis="showContactKpis"
              :sources="sources"
              @getShowContactKpis="value => (showContactKpis = value)"
            />
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-card
            v-show="!showContactKpis"
            color=""
            class="rounded-0"
            elevation="0"
            style="margin-top: 10px;"
          >
            <div>
              <lead-how-did-contact-us-kpis
                style="padding-bottom: 0px;"
                :leads="leads"
                @getFilter="filterHowDidContactUs"
                @getSource="value => (sources = value)"
              />
            </div>
            <!--  <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                style="padding:0px; margin:0px"
                icon
                color="primary"
                @click="showContactKpis = true"
              >
                <v-icon> mdi-chevron-up</v-icon></v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions> -->
          </v-card>
        </v-expand-transition>
      </v-card>
      <div v-if="filterLeadsSearchedSt" class="lists">
        <div
          v-for="(item, i) in getItemsSearchedKanban(leadsSearchedSt)"
          :key="i"
        >
          <div :class="`list list-${item.value}`">
            <header>
              <v-icon size="20" v-if="item.value === 'discarded'">
                mdi-delete
              </v-icon>
              <v-icon size="20" v-if="item.value === 'finished'">
                mdi-trophy
              </v-icon>
              <span>
                {{ item.text }}
              </span>
              <span
                v-if="
                  getValuePhase(filterLeadStatus, item.value) !== item.value
                "
                class="badge badge-info pos-badge"
              >
                ( 0)
              </span>
              <span
                v-else-if="item && item.footer && item.footer.total"
                class="badge badge-info pos-badge"
              >
                ({{ item.footer.total }})
              </span>
              <span v-else class="badge badge-info pos-badge">
                (0)
              </span>
            </header>

            <ul v-if="item.leads.length <= 0">
              Parece que no hay Leads en esta fase.
            </ul>
            <ul v-else>
              <div
                v-for="(lead, index) in item.leads"
                :key="index"
                class="border-default card auto lead-card-container"
                :class="userRole === 'marketing' ? 'no-hover-pointer' : ''"
                @click="setActualLead(lead._id)"
              >
                <lead-card
                  :lead="lead"
                  :contactLeadName="lead.contact_lead_name"
                  :srcBrokerImage="
                    lead.internal_broker
                      ? brokerImageFormat(lead.internal_broker)
                      : brokerImageFormat(lead.internal_admin)
                  "
                  :brokerName="getBrokerName(lead)"
                  :observations="lead.observations"
                  :observations_detail="lead.observations_detail"
                  :budget="parseInt(lead.budget)"
                  :currency="lead.currency"
                  :profilePercentage="
                    lead.profile_percentage | percentageFormat
                  "
                >
                </lead-card>
              </div>
            </ul>
            <footer></footer>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          :class="showDiscardedLeads ? '' : 'lists'"
          v-if="leadsKanbanSt && leadsKanbanSt.length > 0"
        >
          <div v-for="(item, i) in getItemsKanban(leadsKanbanSt)" :key="i">
            <div v-if="showDiscardedLeads" :class="`list-discarded-only`">
              <header>
                <v-icon size="20" v-if="item.value === 'discarded'">
                  mdi-delete
                </v-icon>
                <span>
                  {{ item.text }}
                </span>
                <span
                  v-if="
                    getValuePhase(filterLeadStatus, item.value) !== item.value
                  "
                  class="badge badge-info pos-badge"
                >
                  (0)
                </span>
                <span
                  v-else-if="item && item.footer && item.footer.total"
                  class="badge badge-info pos-badge"
                >
                  ({{ item.footer.total }})
                </span>
                <span v-else class="badge badge-info pos-badge">
                  (0)
                </span>
              </header>
              <v-row v-if="item.loading">
                <div v-for="(item, index) in 10" :key="index" class="mx-2">
                  <v-skeleton-loader
                    width="350px"
                    class="my-4"
                    type="list-item-avatar-two-line,list-item, actions"
                  ></v-skeleton-loader>
                </div>
              </v-row>
              <v-row
                v-else-if="someFilterSelected && item.leads.length <= 0"
                class="my-10 mx-2 grey--text"
              >
                <v-card elevation="0" color="transparent">
                  No se encontraron Leads con los filtros seleccionados.
                </v-card>
              </v-row>
              <v-row
                class="my-10 mx-2 grey--text"
                v-else-if="!someFilterSelected && item.leads.length <= 0"
              >
                <v-card elevation="0" color="transparent">
                  Parece que no hay Leads en esta fase.
                </v-card>
              </v-row>
              <v-row
                v-else-if="
                  getValuePhase(filterLeadStatus, item.value) !== item.value
                "
              >
                <v-card
                  elevation="0"
                  color="transparent"
                  class="my-10 mx-2 grey--text"
                >
                  Tiene seleccionada una fase distinta a esta en los filtros.
                </v-card>
              </v-row>

              <v-row v-else>
                <div
                  v-for="(lead, index) in item.leads"
                  :key="index"
                  class="mx-2 border-default card auto lead-card-container"
                  :class="userRole === 'marketing' ? 'no-hover-pointer' : ''"
                  @click="setActualLead(lead._id)"
                >
                  <lead-card
                    :lead="lead"
                    :contactLeadName="lead.contact_lead_name"
                    :srcBrokerImage="
                      lead.internal_broker
                        ? brokerImageFormat(lead.internal_broker)
                        : brokerImageFormat(lead.internal_admin)
                    "
                    :brokerName="getBrokerName(lead)"
                    :observations="lead.observations"
                    :observations_detail="lead.observations_detail"
                    :budget="parseInt(lead.budget)"
                    :currency="lead.currency"
                    :profilePercentage="
                      lead.profile_percentage | percentageFormat
                    "
                  >
                  </lead-card>
                </div>
                <div v-if="loadMoreAvailable(item.footer)">
                  <v-btn
                    color="primary"
                    class="my-4 py-4"
                    :disabled="item.more"
                    :loading="item.more"
                    @click="loadMoreLeadsByPhase(item)"
                    block
                  >
                    Cargar más
                    <v-icon class="mx-2">
                      mdi-plus-circle
                    </v-icon>
                  </v-btn>
                </div>
                <!-- <div v-else>
                <v-btn block disabled>
                  Limite alcanzado
                </v-btn>
              </div> -->
              </v-row>
              <footer></footer>
            </div>
            <div v-else :class="`list list-${item.value}`">
              <header>
                <v-icon size="20" v-if="item.value === 'discarded'">
                  mdi-delete
                </v-icon>
                <v-icon size="20" v-if="item.value === 'finished'">
                  mdi-trophy
                </v-icon>
                <span>
                  {{ item.text }}
                </span>
                <span
                  v-if="
                    getValuePhase(filterLeadStatus, item.value) !== item.value
                  "
                  class="badge badge-info pos-badge"
                >
                  ( 0)
                </span>
                <span
                  v-else-if="item && item.footer && item.footer.total"
                  class="badge badge-info pos-badge"
                >
                  ({{ item.footer.total }})
                </span>
                <span v-else class="badge badge-info pos-badge">
                  (0)
                </span>
              </header>
              <ul v-if="item.loading">
                <div v-for="(item, index) in 10" :key="index">
                  <v-skeleton-loader
                    class="my-4"
                    type="list-item-avatar-two-line,list-item, actions"
                  ></v-skeleton-loader>
                </div>
              </ul>
              <ul
                v-else-if="someFilterSelected && item.leads.length <= 0"
                class="grey--text"
              >
                No se encontraron Leads con los filtros seleccionados.
              </ul>
              <ul v-else-if="!someFilterSelected && item.leads.length <= 0">
                Parece que no hay Leads en esta fase.
              </ul>
              <ul
                v-else-if="
                  getValuePhase(filterLeadStatus, item.value) !== item.value
                "
              >
                Tiene seleccionada una fase distinta a esta en los filtros.
              </ul>

              <ul v-else>
                <div
                  v-for="(lead, index) in item.leads"
                  :key="index"
                  class="border-default card auto lead-card-container"
                  :class="userRole === 'marketing' ? 'no-hover-pointer' : ''"
                  @click="setActualLead(lead._id)"
                >
                  <lead-card
                    :lead="lead"
                    :contactLeadName="lead.contact_lead_name"
                    :srcBrokerImage="
                      lead.internal_broker
                        ? brokerImageFormat(lead.internal_broker)
                        : brokerImageFormat(lead.internal_admin)
                    "
                    :brokerName="getBrokerName(lead)"
                    :observations="lead.observations"
                    :observations_detail="lead.observations_detail"
                    :budget="parseInt(lead.budget)"
                    :currency="lead.currency"
                    :profilePercentage="
                      lead.profile_percentage | percentageFormat
                    "
                  >
                  </lead-card>
                </div>
                <div v-if="loadMoreAvailable(item.footer)">
                  <v-btn
                    color="primary"
                    class="my-4 py-4"
                    :disabled="item.more"
                    :loading="item.more"
                    @click="loadMoreLeadsByPhase(item)"
                    block
                  >
                    Cargar más
                    <v-icon class="mx-2">
                      mdi-plus-circle
                    </v-icon>
                  </v-btn>
                </div>
                <!-- <div v-else>
                <v-btn block disabled>
                  Limite alcanzado
                </v-btn>
              </div> -->
              </ul>
              <footer></footer>
            </div>
          </div>
        </div>
        <div v-else>
          <v-card width="100vw" height="90vh" elevation="0">
            <v-container fill-height>
              <v-row justify="center" align="center">
                <v-col cols="12" justify="center" align="center">
                  <v-progress-circular
                    :width="5"
                    :size="100"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
                <v-col cols="12" justify="center" align="center">
                  <div>
                    Cargando Leads ...
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </div>
      </div>
    </div>
    <lead-detail-modal
      ref="leadDetailModal"
      :page="'kanban'"
      :dialog="isDialogActive"
      @setDialog="setDialog"
    />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import moment from "moment";
moment.locale("es");

export default {
  components: {
    LeadSearchField: () => import("@/components/lead/LeadSearchField.vue"),
    LeadDuplicates: () => import("@/components/lead/LeadDuplicates.vue"),
    LeadHowDidContactUsKpis: () =>
      import("@/components/lead/LeadHowDidContactUsKpis.vue"),
    LeadDetailModal: () => import("@/components/lead/LeadDetailModal.vue"),
    LeadCard: () => import("@/components/lead/LeadCard.vue")
  },
  data() {
    return {
      showContactKpis: true,
      sources: ["3"],
      isDialogActive: false,
      userRole: localStorage.getItem("user_role") || ""
    };
  },
  computed: {
    ...mapState({
      loading: state => state.leads.loading,
      leadsKanbanSt: state => state.leads.leadsKanban,
      filtersSt: state => state.leads.filters,
      // lead: state => state.leads.actualItem,
      filtersSelectedSt: state => state.leads.filtersSelected,
      filtersSelectedChangedSt: state => state.leads.filtersSelectedChanged,
      someFilterSelected: state => state.leads.filterSomeFilterSelected,
      showDiscardedLeads: state => state.leads.filterShowDiscarded,
      showDiscardedAndActive: state => state.leads.filterShowDiscardedAndActive,
      showLeadsDuplicates: state => state.leads.filterShowLeadsDuplicates,
      realEstateGroupLoading: state =>
        state.realEstateGroup.realEstateGroupLoading,
      realEstateGroup: state => state.realEstateGroup.realEstateGroup,
      filterLeadStatus: state => state.leads.filterLeadStatus,
      leadsSearchedSt: state => state.leads.leadsSearched,
      filterLeadsSearchedSt: state => state.leads.filterLeadsSearched
    }),
    ...mapGetters("leads", {
      leads: "filteredLeads"
    }),
    searchTextFilter: {
      get() {
        return this.$store.state.leads.filterText;
      },
      set(newValue) {
        this.$store.commit(
          "leads/setFilterText",
          newValue.trim().toLowerCase()
        );
      }
    }
  },
  filters: {
    percentageFormat(percentage) {
      if (!percentage || isNaN(percentage)) return 0;
      let percentageParsed = parseInt(percentage);
      return percentageParsed;
    },
    dateFormat(date, isFullDate = false) {
      if (!date) return "";
      let format = "DD-MMM-YYYY";
      if (isFullDate) format = "DD-MMM-YYYY h:mm a";
      let dateParsed = moment(String(date)).format(format);
      return dateParsed;
    },

    interestFormat(targetAction, propertyType, interestedZones) {
      let htmlInterested = "";
      // let propertyTypeTest = ['Departamento', 'Departamento 2', 'Departamento 3']

      if (targetAction) htmlInterested += targetAction;
      if (propertyType && propertyType.length > 0) {
        htmlInterested += " " + propertyType.toString();
      }
      if (interestedZones) htmlInterested += " " + interestedZones;
      return htmlInterested;
    }
  },
  created() {
    if (this.filtersSt) {
      this.fetchGetLeadsKanbanByPhaseSt({
        filter: this.filtersSelectedSt,
        page: 0,
        phases: this.filtersSt.phases
      });
    }
    // this.fetchRealEstateListStore();
  },
  methods: {
    // pasar a una librería
    getValuePhase(data, value) {
      if (!data) {
        return value;
      } else {
        switch (data.phase) {
          case "active":
            return data.tracking_phase;
          case "in-operation":
            return data.operation_phase;

          default:
            return data.phase;
        }
      }
    },
    loadMoreLeadsByPhase(data) {
      this.fetchMoreLeadsKanbanByPhaseSt(data);
    },
    loadMoreAvailable(data) {
      return data.page < Math.ceil(data.pages) - 1;
    },
    getSafeNestedField(fn, defaultVal) {
      try {
        return fn();
      } catch (e) {
        return defaultVal;
      }
    },
    getItemsSearchedKanban(items) {
      const phases = this.filtersSt.phases ? this.filtersSt.phases : [];
      let list = [];
      for (let i = 0; i < phases.length; i++) {
        list.push({
          data: phases[i].data,
          text: phases[i].text,
          value: phases[i].value,
          error: false,
          footer: null,
          index: i,
          leads: items.filter(l => {
            if (phases[i].data.phase === "active") {
              return (
                l.phase === "active" &&
                l.tracking_phase === phases[i].data.tracking_phase
              );
            } else if (phases[i].data.phase === "in-operation") {
              return (
                l.phase === "in-operation" &&
                l.tracking_phase === phases[i].data.operation_phase
              );
            } else {
              return l.phase === phases[i].data.phase;
            }
          }),
          loading: false,
          more: false,
          update: false
        });
      }
      return list;
    },
    getItemsKanban(items) {
      if (items) {
        if (this.showDiscardedAndActive) {
          return items;
        } else if (this.showDiscardedLeads) {
          return items.filter(i => {
            return i.value === "discarded";
          });
        } else {
          return items.filter(i => {
            return i.value !== "discarded";
          });
        }
      } else {
        return items.filter(i => {
          return i.value !== "discarded";
        });
      }
    },
    ...mapActions({
      fetchActualLead: "leads/fetchActualLead",
      fetchGetLeadsKanbanByPhaseSt: "leads/fetchGetLeadsKanbanByPhase",
      fetchMoreLeadsKanbanByPhaseSt: "leads/fetchMoreLeadsKanbanByPhase",
      fetchRealEstateListStore: "realEstateDevelopments/getRealEstateDevAssgign"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading",
      setFiltersSelectedSt: "leads/setFiltersSelected",
      setSecondaryLead: "leads/setSecondaryLead"
    }),
    brokerImageFormat(broker) {
      if (!(broker && broker.name)) return "";
      let brokerSrcImg = "";
      let brokerName = "";
      if (broker.name) brokerName = broker.name;
      if (
        broker.media &&
        broker.media.featured_image &&
        broker.media.featured_image.src
      )
        brokerSrcImg = broker.media.featured_image.src;
      if (!brokerName) brokerName = "C28";
      if (!brokerSrcImg)
        brokerSrcImg =
          "https://dummyimage.com/400x200/262d3c/fff&text=" +
          brokerName.substring(0, 2);

      return brokerSrcImg;
    },
    getBrokerName(lead) {
      if (lead.internal_broker) {
        return lead.internal_broker.name;
      } else if (lead.internal_admin) {
        return lead.internal_admin.name;
      } else {
        return "";
      }
    },
    sortListExperideToPosponent(list) {
      return list.sort(function(a, b) {
        let dateA =
          a.postponed.date_at === null ? new Date() : a.postponed.date_at;
        let dateB =
          b.postponed.date_at === null ? new Date() : b.postponed.date_at;
        return new Date(dateA) - new Date(dateB);
      });
    },
    setActualLead(leadId) {
      // si es usuario es marketing no realizar ninguna acción
      if (this.userRole !== "marketing") {
        let self = this;
        self.setLoading(true);
        self.setDialog(true);
        // resetear leads seleccionados (checkboxes)
        self.selectedItems = [];
        //this.$refs.leadDetailModal.getTrafficData();
        this.fetchActualLead(leadId)
          .then(lead => {
            let data = JSON.parse(JSON.stringify(lead));
            this.$store.commit("leads/setSecondaryLead", data);
            self.setLoading(false);
          })
          .finally(() => {
            setTimeout(() => {
              self.setLoading(false);
            }, 1000);
          });
      }
    },
    setDialog(isActive) {
      this.isDialogActive = isActive;
    },
    filterHowDidContactUs(setValue) {
      this.$store.commit("leads/setFilterHowDidContactUs", setValue);
    }
  },
  watch: {
    filtersSt(newValue, oldValue) {
      if (oldValue == null || oldValue === undefined) {
        if (newValue) {
          this.fetchGetLeadsKanbanByPhaseSt({
            filter: this.filtersSelectedSt,
            page: 0,
            phases: newValue.phases
          });
        }
      }
    },
    lead(newValue) {
      this.lead = newValue;
    }
  }
};
</script>

<style lang="scss" scoped>
// Layout
$navbar-height: 30px;
$list-width: 400px;
$gap: 10px;
$scrollbar-thickness: 5px;
$list-header-height: 50px;
$list-footer-height: 36px;
// Misc
$list-border-radius: 10px;
$card-border-radius: 0px;
// Colors
$board-bg-color: #2d3846;
$list-bg-color: #f1f5f7;

body {
  margin: 0;

  font-family: "Roboto";
  font-size: 14px;
  line-height: 1.3em;
}

.card {
  position: relative;
}

.ui {
  height: 100vh;

  display: grid;
  //grid-template-columns: 100%;

  background-color: $board-bg-color;
  color: #2d3846;
}

.navbar {
  padding-left: $gap;
  display: flex;
  margin-bottom: 26px;
  align-items: center;
  &.board {
    font-size: 1.1rem;
  }
}

.lists {
  display: flex;
  overflow-x: auto;
  //width: 100%; // This is needed for FF < 54
  > * {
    flex: 0 0 auto; // 'rigid' lists
    margin-left: $gap;
  }
  &::after {
    content: "";
    flex: 0 0 $gap;
  }
  height: 90vh;
}

.list {
  width: $list-width;
  height: calc(100% - #{$gap} - #{$scrollbar-thickness});
  background-color: $list-bg-color;

  > * {
    color: #333;

    padding: 0 $gap;
  }

  header {
    line-height: $list-header-height;
    font-size: 16px;
    font-weight: bold;
    border-top-left-radius: $list-border-radius;
    border-top-right-radius: $list-border-radius;
  }

  footer {
    line-height: $list-footer-height;
    border-bottom-left-radius: $list-border-radius;
    border-bottom-right-radius: $list-border-radius;
    color: #888;
  }

  ul {
    list-style: none;
    margin: 0;
    max-height: calc(100% - #{$list-header-height} - #{$list-footer-height});
    overflow-y: auto;

    li {
      background-color: #fff;
      padding: $gap;
      &:not(:last-child) {
        margin-bottom: $gap;
      }

      border-radius: $card-border-radius;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

      img {
        display: block;
        width: calc(100% + 2 * #{$gap});
        margin: -$gap 0 $gap (-$gap);
        border-top-left-radius: $card-border-radius;
        border-top-right-radius: $card-border-radius;
      }
    }
  }
}

.border-no-response {
  border-style: solid;
  border-color: #2d3846;
}
.vl-no-response {
  border-left: 12px solid;
  border-color: #2d3846;
  border-radius: 3px;
  height: auto;
}
.pos-text-unassigned {
  margin-top: -35px;
  position: absolute;
  padding-left: 215px;
  margin-left: 0px;
  font-size: 11px;
}
.pos-text-discarded {
  margin-top: -35px;
  position: absolute;
  padding-left: 182px;
  margin-left: 0px;
  font-size: 11px;
}
.pos-badge {
  color: #b9b9b9;
  font-size: smaller;
  margin-left: 4px;
}
.text-href {
  color: #6495ed;
  font-weight: bold;
  margin-left: -4px !important;
}
//pruenbas de estilos dinamicos
.text-blue {
  color: blue;
  font-weight: bold;
}
.text-green {
  color: greenyellow;
  font-weight: bold;
}
// Pruebas de estilos dinamicos
.list.list-default .lead-card {
  border-left: 10px solid;
  border-color: #ff4500;
  height: auto;
}

.list.list-unassigned .lead-card {
  border-left: 10px solid;
  border-color: #ff4500;
  height: auto;
}

.clock-icon {
  float: right;
  color: #ff4500;
}

.row-parent {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-spacing: 5px;
}

.row-child {
  display: table-cell;
}
.btn-info {
  border-color: rgba(30, 39, 46, 1) !important;
  color: rgba(30, 39, 46, 1) !important;
  position: absolute;
  bottom: 16px;
  margin: auto;
  right: 1182px;
}
.list.list-finished {
  background-color: #fffbe8;
}
.list-discarded-only {
  background-color: #eae9e9;
  width: 100vw;
  height: auto;
}
.list.list-unassigned {
  background-color: #ffeaea;
}
.list.list-discarded {
  background-color: #eae9e9;
}
.lead-card-container:hover {
  cursor: pointer;
}
.no-hover-pointer:hover {
  cursor: inherit;
}
</style>
